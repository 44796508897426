import { fetchSafe } from '../services/fetch.safe';
import { ManageRedisKeyDetails } from '../types/management/ManageRedisKeyDetails';

const clearRedisCache = () => {
    return fetchSafe('/clear-cache', { method: 'POST' })
}

const getRedisCacheKeys = (
): Promise<ManageRedisKeyDetails[]> => {
    return fetchSafe('/api/storage/keys')
}

export const manageStorageService = {
    clearRedisCache,
    getRedisCacheKeys
}
