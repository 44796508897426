import { fetchSafe } from './fetch.safe';
import { EmailPreferences } from '../types/email-preferences/EmailPreferences';
import { responseType } from '../constants';

export const emailPreferencesService = {
    getEmailPreferences,
    saveEmailPreferences,
}

function getEmailPreferences(): Promise<EmailPreferences> {
    return fetchSafe('/api/email-preferences');
}

function saveEmailPreferences(preferences: EmailPreferences): Promise<void> {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(preferences),
        responseType: responseType.none
    };

    return fetchSafe('/api/email-preferences', requestOptions);
}