import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux';
import { ActionType } from 'typesafe-actions';
import { manageEmailPreferencesActions } from '../actions/manage-email-preferences.actions';
import { emailPreferencesService } from '../services';
import { errorActions } from '../actions';
import { AppState } from '../types/state/AppState';
import { AlertOption, EmailPreferences } from '../types/email-preferences/EmailPreferences';
import { imUserConfigService } from '../services';
import { ImUserConfig } from '../types/user-config/UserConfig';
import { UserConfigType } from '../types/user-config/UserConfigType';
import { IssuanceMonitorEmailPreferences } from '../types/email-preferences/IssuanceMonitorEmailPreferences';
import { createAmrPipelineActions } from '../actions';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { CloManagersEmailPreferences } from '../types/email-preferences/CloManagersEmailPreferences';
import { Company } from '../types/amr-pipeline/models/Company';
import { amrCompaniesService } from '../services/amr-companies.service';
import { roles } from '../constants';
import { user } from '../user';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { AlertEnabledState } from '../types/enums/AlertEnabledState';
import { banksService } from '../services/banks.service';
import { BanksEmailPreferences } from '../types/email-preferences/BanksEmailPreferences';
import { combineBanks, hasBankAnalyticsAlertsChangeAccess } from '../utils/amr-pipeline.utils';
import { Bank, BwicBank } from '../types/banks/Bank';
import { bankImApRequiredFeatures } from '../constants/banks/bankImApRequiredFeatures';
import { emailPreferencesActions } from '../actions/email-preferences.actions';
import { RequestState } from '../constants/request-state';
import { HavingDealType } from '../types/amr-pipeline/enums/HavingDealType';
import { ArrangerPipelineEmailPreferences } from '../types/email-preferences/ArrangerPipelineEmailPreferences';

const amrPipelineActions = createAmrPipelineActions(PipelineType.Deals);

function* watchGetInitialDataRequest(action: ActionType<typeof manageEmailPreferencesActions.getInitialDataRequest>) {
    try {
        const { withIssuanceMonitorAccess } = action.payload;
        let userConfig: ImUserConfig[] | undefined;
        let managers: Company[] | undefined;
        let banks: Bank[] | undefined;

        if (withIssuanceMonitorAccess) {
            let amrBanks: Company[];
            let bwicBanks: BwicBank[];

            const loadFilters: Action<any> = yield call(amrPipelineActions.loadFilters);
            const initFilter: Action<any> = yield call(amrPipelineActions.initFilter);

            yield put(loadFilters);
            yield put(initFilter);

            [amrBanks, bwicBanks, managers, userConfig] = yield all([
                call(amrCompaniesService.getBanksList),
                call(banksService.getBwicBanksList),
                call(amrCompaniesService.getManagersList, HavingDealType.CloManagers),
                call(imUserConfigService.getUserConfig),
            ]);

            banks = combineBanks(amrBanks, bwicBanks);
        }

        const initialData: EmailPreferences = yield call(emailPreferencesService.getEmailPreferences);

        yield put(manageEmailPreferencesActions.getInitialDataSuccess(initialData, userConfig, managers, banks));
        yield put(emailPreferencesActions.requestResult(RequestState.success, initialData));
    } catch (e) {
        yield put(errorActions.criticalError(e));
    }
}

function* watchSaveRequest() {
    const editIssuanceMonitorPreferences: IssuanceMonitorEmailPreferences = yield select(
        (state: AppState) => state.manageEmailPreferences.editIssuanceMonitorPreferences,
    );
    const editCloManagerPreferences: CloManagersEmailPreferences = yield select(
        (state: AppState) => state.manageEmailPreferences.editCloManagersPreferences,
    );

    const userCompany: Company = yield select((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const isCollateralManager = user.hasRoles(roles.CollateralManager);
    const hasImSubscription = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess);
    const hasBwicSubscription = user.hasFeatures(SubscriptionFeature.BwicMonitorAlerts);
    const hasDealerInventorySubscription = user.hasFeatures(SubscriptionFeature.InventoryAlerts);

    try {
        const cloManagerBwicAlerts = editCloManagerPreferences.cloManagerBwicAlert.value;
        const cloManagerDealerInventoryAlerts = editCloManagerPreferences.cloManagerDealerInventoryAlert.value;

        let cloManagerBwicAlertsConfig;
        let cloManagerDiAlertsConfig;

        if (hasBwicSubscription && cloManagerBwicAlerts.length) {
            cloManagerBwicAlertsConfig = {
                type: UserConfigType.cloManagerBwicAlert,
                value: JSON.stringify(cloManagerBwicAlerts),
            };
        }

        if (hasDealerInventorySubscription && cloManagerDealerInventoryAlerts.length) {
            cloManagerDiAlertsConfig = {
                type: UserConfigType.cloManagerDealerInventoryAlert,
                value: JSON.stringify(cloManagerDealerInventoryAlerts),
            };
        }

        yield all([
            cloManagerBwicAlertsConfig && call(imUserConfigService.createOrUpdateUserConfig, cloManagerBwicAlertsConfig),
            cloManagerDiAlertsConfig && call(imUserConfigService.createOrUpdateUserConfig, cloManagerDiAlertsConfig)
        ]);

        if (hasImSubscription) {
            const singleUpdateOption = editIssuanceMonitorPreferences.imAlertConfig.value.alertOption;
            const newTransactionOption = editIssuanceMonitorPreferences.newTransactionAlertConfig.value.alertOption;
            const statisticOption = editIssuanceMonitorPreferences.weeklyStatsAlertConfig.value.emailOption;

            const cloManagerAnalyticsAlert = editCloManagerPreferences.cloManagerAnalyticsAlert.value.find(
                a => a.companyReferenceName === userCompany.referenceName,
            );
            const cloManagerImAlerts = editCloManagerPreferences.cloManagerIssuanceMonitorAlert.value;
            const cloManagerApAlerts = editCloManagerPreferences.cloManagerArrangerPipelineAlert.value;

            const filterData = editIssuanceMonitorPreferences.filtersConfig.ImFilter.value.map(
                ({ referenceName, alertOption }) => ({
                    referenceName,
                    alertOption,
                }),
            );
            const imAlertsConfig = {
                type: UserConfigType.imAlert,
                value: JSON.stringify({ alertOption: singleUpdateOption }),
            } as ImUserConfig;

            const newTransactionConfig = {
                type: UserConfigType.newPublishedTransactionAlert,
                value: JSON.stringify({ alertOption: newTransactionOption }),
            } as ImUserConfig;

            const imStatisticsConfig = {
                type: UserConfigType.weeklyStatsEmail,
                value: JSON.stringify({ emailOption: statisticOption }),
            } as ImUserConfig;

            const cloManagerAnalyticsAlertsConfig = {
                type: UserConfigType.cloManagerAnalyticsAlert,
                value: JSON.stringify(cloManagerAnalyticsAlert),
            };

            const cloManagerImAlertsConfig = {
                type: UserConfigType.cloManagerIssuanceMonitorAlert,
                value: JSON.stringify(cloManagerImAlerts),
            };

            const cloManagerApAlertsConfig = {
                type: UserConfigType.cloManagerArrangerPipelineAlert,
                value: JSON.stringify(cloManagerApAlerts),
            };

            yield all([
                call(imUserConfigService.createOrUpdateUserConfig, imAlertsConfig),
                call(imUserConfigService.createOrUpdateUserConfig, newTransactionConfig),
                call(imUserConfigService.createOrUpdateUserConfig, imStatisticsConfig),
                cloManagerAnalyticsAlert?.alertOption === AlertOption.Never && cloManagerAnalyticsAlert.referenceName
                    ? call(
                        imUserConfigService.deleteUserConfig,
                        UserConfigType.cloManagerAnalyticsAlert,
                        cloManagerAnalyticsAlert.referenceName,
                    )
                    : cloManagerAnalyticsAlert?.alertOption &&
                    call(imUserConfigService.createOrUpdateUserConfig, cloManagerAnalyticsAlertsConfig),
                cloManagerImAlerts.length &&
                call(imUserConfigService.createOrUpdateUserConfig, cloManagerImAlertsConfig),
                cloManagerApAlerts.length &&
                call(imUserConfigService.createOrUpdateUserConfig, cloManagerApAlertsConfig),
                filterData.length && call(imUserConfigService.updateFilterEmailAlertsConfig, filterData),
            ]);
        } else if (isCollateralManager) {
            const cloManagerAnalyticsAlert = editCloManagerPreferences.cloManagerAnalyticsAlert.value.find(
                a => a.companyReferenceName === userCompany.referenceName,
            );

            const cloManagerAnalyticsAlertsConfig = {
                type: UserConfigType.cloManagerAnalyticsAlert,
                value: JSON.stringify(cloManagerAnalyticsAlert),
            };

            const request = cloManagerAnalyticsAlert?.alertOption === AlertOption.Never && cloManagerAnalyticsAlert.referenceName
                ? call(
                    imUserConfigService.deleteUserConfig,
                    UserConfigType.cloManagerAnalyticsAlert,
                    cloManagerAnalyticsAlert.referenceName,
                )
                : cloManagerAnalyticsAlert?.alertOption &&
                call(imUserConfigService.createOrUpdateUserConfig, cloManagerAnalyticsAlertsConfig);

            yield request;
        }

        yield put(manageEmailPreferencesActions.saveSuccess());
        yield put(manageEmailPreferencesActions.getInitialDataRequest(true));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(manageEmailPreferencesActions.saveFailure());
    }
}

function* watchSaveArrangerPipelineRequest() {
    const withImAccess = user.hasAllFeatures(
        SubscriptionFeature.IssuanceMonitorFullAccess
    );

    if (!withImAccess) {
        return;
    }

    const editArrangerPipelinePreferences: ArrangerPipelineEmailPreferences =
        yield select(
            (state: AppState) =>
                state.manageEmailPreferences.editArrangerPipelinePreferences
        );

    try {
        const apAlertOption =
            editArrangerPipelinePreferences.apAlertConfig.value;
        const apConfig = {
            type: UserConfigType.arrangerPipelineAlert,
            value: JSON.stringify(apAlertOption),
        };

        const request = call(
            imUserConfigService.createOrUpdateUserConfig,
            apConfig
        );

        yield request;

        yield put(manageEmailPreferencesActions.saveSuccess());
        yield put(manageEmailPreferencesActions.getInitialDataRequest(true));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(manageEmailPreferencesActions.saveFailure());
    }
}

function* watchSaveBanksRequest() {
    const banks: Company[] | undefined = yield select(
        (state: AppState) => state.manageEmailPreferences.banks
    );
    const editBanksPreferences: BanksEmailPreferences = yield select(
        (state: AppState) => state.manageEmailPreferences.editBanksPreferences
    );
    const userCompany: Company = yield select(
        (state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany
    );

    const withAnalyticsAccess = hasBankAnalyticsAlertsChangeAccess(banks, userCompany );
    const withDiAccess = user.hasFeatures(SubscriptionFeature.InventoryAlerts);
    const withImApAccess = user.hasAllFeatures(...bankImApRequiredFeatures);

    if (!withAnalyticsAccess && !withDiAccess && !withImApAccess) {
        return;
    }

    try {
        if (withAnalyticsAccess) {
            const bankAnalyticsAlerts =
                editBanksPreferences.bankAnalyticsAlert.value.find(
                    (a) => a.companyReferenceName === userCompany.referenceName
                );

            const bankAnalyticsConfig = {
                type: UserConfigType.bankAnalyticsAlert,
                value: JSON.stringify(bankAnalyticsAlerts),
            };

            const request =
                bankAnalyticsAlerts?.alertOption === AlertOption.Never &&
                bankAnalyticsAlerts.referenceName
                    ? call(
                          imUserConfigService.deleteUserConfig,
                          UserConfigType.bankAnalyticsAlert,
                          bankAnalyticsAlerts.referenceName
                      )
                    : bankAnalyticsAlerts?.alertOption &&
                      call(
                          imUserConfigService.createOrUpdateUserConfig,
                          bankAnalyticsConfig
                      );

            yield request;
        }

        if (withDiAccess) {
            const bankDiAlerts =
                editBanksPreferences.bankDealerInventoryAlert.value;

            const bankDiAlertsConfig = {
                type: UserConfigType.bankDealerInventoryAlert,
                value: JSON.stringify(bankDiAlerts),
            };

            yield call(
                imUserConfigService.createOrUpdateUserConfig,
                bankDiAlertsConfig
            );
        }

        if (withImApAccess) {
            const bankImAlerts =
                editBanksPreferences.bankIssuanceMonitorAlert.value;
            const bankApAlerts =
                editBanksPreferences.bankArrangerPipelineAlert.value;

            const bankImAlertsConfig = {
                type: UserConfigType.bankIssuanceMonitorAlert,
                value: JSON.stringify(bankImAlerts),
            };

            const bankApAlertsConfig = {
                type: UserConfigType.bankArrangerPipelineAlert,
                value: JSON.stringify(bankApAlerts),
            };

            yield all([
                call(
                    imUserConfigService.createOrUpdateUserConfig,
                    bankImAlertsConfig
                ),
                call(
                    imUserConfigService.createOrUpdateUserConfig,
                    bankApAlertsConfig
                ),
            ]);
        }

        yield put(manageEmailPreferencesActions.saveSuccess());
        yield put(manageEmailPreferencesActions.getInitialDataRequest(true));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(manageEmailPreferencesActions.saveFailure());
    }
}

function* watchSaveEmailPreferences() {
    const initialPreferences: EmailPreferences = yield select((state: AppState) =>
        state.manageEmailPreferences.initialPreferences
    );
    const editPreferences: EmailPreferences = yield select((state: AppState) =>
        state.manageEmailPreferences.editPreferences
    );

    try {
        yield call(emailPreferencesService.saveEmailPreferences, editPreferences);
        const emailPreferences: EmailPreferences = yield call(emailPreferencesService.getEmailPreferences);
        yield put(manageEmailPreferencesActions.saveEmailPreferencesSuccess(emailPreferences));
        yield put(emailPreferencesActions.requestResult(RequestState.success, emailPreferences));

        const isPortfolioBwicAlertChanged =
            initialPreferences?.portfolioBwicAlertState !== emailPreferences.portfolioBwicAlertState;
        const isPortfolioInventoryAlertChanged =
            initialPreferences?.portfolioDealersInventoryAlertState !== emailPreferences.portfolioDealersInventoryAlertState;
        const isOutOfRiPeriodAlertChanged = initialPreferences?.portfolioOutOfRiPeriodAlertState !== emailPreferences.portfolioOutOfRiPeriodAlertState;
        const isOutOfNcPeriodAlertChanged = initialPreferences?.portfolioOutOfNcPeriodAlertState !== emailPreferences.portfolioOutOfNcPeriodAlertState;
        const isPortfolioCleansingNoticeAlertChanged = initialPreferences?.portfolioCleansingNoticeAlertState !== emailPreferences.portfolioCleansingNoticeAlertState;
        const isPortfolioIssuanceMonitorAlertChanged = initialPreferences?.portfolioIssuanceMonitorAlertState !== emailPreferences.portfolioIssuanceMonitorAlertState;
        const isPortfolioRollerDeadlineAlertChanged = initialPreferences?.portfolioRollerDeadlineAlertState !== emailPreferences.portfolioRollerDeadlineAlertState;


        if (
            isPortfolioBwicAlertChanged ||
            isPortfolioInventoryAlertChanged ||
            isPortfolioCleansingNoticeAlertChanged ||
            isPortfolioIssuanceMonitorAlertChanged ||
            isOutOfRiPeriodAlertChanged ||
            isOutOfNcPeriodAlertChanged ||
            isPortfolioRollerDeadlineAlertChanged
        ) {
            const bwicAlertEnabled = isPortfolioBwicAlertChanged
                ? emailPreferences.portfolioBwicAlertState === AlertEnabledState.Enabled
                : undefined;

            const inventoryAlertEnabled = isPortfolioInventoryAlertChanged
                ? emailPreferences.portfolioDealersInventoryAlertState === AlertEnabledState.Enabled
                : undefined;

            const outOfRiAlertEnabled = isOutOfRiPeriodAlertChanged
                ? emailPreferences.portfolioOutOfRiPeriodAlertState === AlertEnabledState.Enabled
                : undefined;

            const outOfNcAlertEnabled = isOutOfNcPeriodAlertChanged
                ? emailPreferences.portfolioOutOfNcPeriodAlertState === AlertEnabledState.Enabled
                : undefined;

            const cleansingNoticeAlertEnabled = isPortfolioCleansingNoticeAlertChanged
                ? emailPreferences.portfolioCleansingNoticeAlertState === AlertEnabledState.Enabled
                : undefined;
     
            const issuanceMonitorAlertEnabled = isPortfolioIssuanceMonitorAlertChanged
                ? emailPreferences.portfolioIssuanceMonitorAlertState === AlertEnabledState.Enabled
                : undefined;

            const rollerDeadlineAlertEnabled = isPortfolioRollerDeadlineAlertChanged
                ? emailPreferences.portfolioRollerDeadlineAlertState === AlertEnabledState.Enabled
                : undefined;

            yield put(
                manageEmailPreferencesActions.portfolioGlobalAlertsChange(
                    bwicAlertEnabled,
                    inventoryAlertEnabled,
                    cleansingNoticeAlertEnabled,
                    issuanceMonitorAlertEnabled,
                    outOfRiAlertEnabled,
                    outOfNcAlertEnabled,
                    rollerDeadlineAlertEnabled,
                ),
            );
        }
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(manageEmailPreferencesActions.saveEmailPreferencesFailure());
    }
}

export function* watchManageEmailPreferences() {
    yield takeEvery(manageEmailPreferencesActions.getInitialDataRequest, watchGetInitialDataRequest);
    yield takeEvery(manageEmailPreferencesActions.saveRequest, watchSaveRequest);
    yield takeEvery(manageEmailPreferencesActions.saveArrangerPipelineRequest, watchSaveArrangerPipelineRequest);
    yield takeEvery(manageEmailPreferencesActions.saveBanksRequest, watchSaveBanksRequest);
    yield takeEvery(manageEmailPreferencesActions.saveEmailPreferencesRequest, watchSaveEmailPreferences);
}
